.webixappstart {
  opacity: 0;
}
.webixapp {
  transition: opacity 500ms;
  opacity: 1;
}
/*data loading status*/
.status_good,
.status_error,
.status_saving {
  text-align: center;
  margin-left: -12px;
}
.status_error {
  color: red;
}
.status_good {
  color: green;
}
/*login form*/
.invalid_login .webix_header > div {
  background: #FC3636;
  transition: background 1s;
}
.invalid_login {
  -webkit-animation: formshake .5s linear;
}
@-webkit-keyframes formshake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}
.login.scaleY {
  transform: scaleY(1.25);
}
.custom-pass .webix_icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
.custom-pass .webix_icon:hover {
  color: #1CA1C1;
}
