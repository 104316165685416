.icon .wxi-check {
  color: #1ca1c1;
}
.icon .webix_icon {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding-top: 10px;
}
.icon .webix_icon:hover {
  color: #1ca1c1;
  background-color: #F4F5F9;
}
